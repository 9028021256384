<template>
  <div class="homeModuleManage">
    <ds-header title="首页模块管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="模块名称：">
        <a-input v-model.trim="searchForm.modularName" placeholder="请输入模块名称" class="inputClass" />
      </a-form-item>

      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addModuleManage()"> <a-icon type="plus" /> 添加 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="edit(record)"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="deleteById(record)"> 删除 </a-button>
        </span>
      </a-table>
    </div>
    <a-modal v-model="visibleFloor" :title="title" :footer="null" :maskClosable="false" :closable="false">
      <a-form-model layout="inline" ref="ruleForm" :model="params" :rules="rules">
        <a-form-model-item label="模块名称：" prop="moduleTitle">
          <a-input v-model.trim="params.moduleTitle" :maxLength="10" placeholder="请输入模块名称" class="inputClass" />
        </a-form-model-item>

        <a-form-model-item label="模块编号：" prop="moduleType">
          <a-input v-model.trim="params.moduleType" placeholder="请输入模块编号" class="inputClass" :maxLength="4" />
          <p class="moduleNo">模块编号请填写技术侧提供的编号</p>
        </a-form-model-item>

        <a-form-model-item label="模块图标：" style="padding-left: 2.5%">
          <a-upload
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="IMG_API + '/oss/files'"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img v-if="params.moduleIcon" :src="params.moduleIcon" alt="avatar" class="imgUpload" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
            </div>
          </a-upload>
        </a-form-model-item>
        <p class="imgText">建议上传小于2MB的PNG、JPG图片，图片尺寸1：1。</p>
      </a-form-model>

      <div style="margin-left: 70%">
        <a-button type="primary" class="footerButton" @click="submitModule"> 提交 </a-button>
        <a-button @click="cancel">返回</a-button>
      </div>
    </a-modal>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import { mapState } from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import DSTable from "@/components/DSComponents/DSTable";
import debounce from "lodash/debounce";
import { modularInfoList, addModule, deleteModule, modelarInfoById, editModule } from "@/api/modularInfoList";
import moment from "moment";

export default {
  name: "homeModuleManage",
  components: {
    DSTable,
    ConfirmBoxModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      IMG_API: IMG_API,
      // 图片loading
      loading: false,
      // 图片地址
      imageUrl: "",
      searchForm: {
        modularName: "",
      },
      title: "",
      id: "",
      params: {
        // 模块icon地址
        moduleIcon: "",
        // 模块名称
        moduleTitle: "",
        // 默认传空字段
        moduleDetailUrl: "",
        // 模块编号
        moduleType: "",
      },
      rules: {
        moduleTitle: [
          {
            required: true,
            message: "请填写完整信息",
            trigger: "blur",
          },
          { required: false, pattern: new RegExp(/^[^\s]*$/, "g"), message: "禁止输入空格" },
        ],
        moduleType: [
          {
            required: true,
            message: "请填写完整信息",
            trigger: "blur",
          },
          { required: false, pattern: new RegExp(/^(0|\+?[1-9][0-9]*)$/, "g"), message: "只能输入数字" },
        ],
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "模块名称",
            dataIndex: "moduleTitle",
            key: "moduleTitle",
          },
          {
            title: "模块编号",
            dataIndex: "moduleType",
            key: "moduleType",
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      visibleFloor: false,
    };
  },
  mounted() {
    this.searchFormList();
  },
  methods: {
    // 获取详情
    async modelarInfoById(id) {
      let obj = {
        id: this.id,
      };
      const res = await modelarInfoById(obj);
      if (res.code == 200) {
        this.params = {
          // 模块icon地址
          moduleIcon: res.data.moduleIcon,
          // 模块名称
          moduleTitle: res.data.moduleTitle,
          // 默认传空字段
          moduleDetailUrl: "",
          // 模块编号
          moduleType: res.data.moduleType,
        };
      }
    },

    // 处理获取列表时数据
    searchFormList() {
      let obj = this.searchForm.modularName;
      if (obj == "") {
        obj = "null";
        this.modularInfoList(this.table.pagination.pageSize, this.table.pagination.current - 1, obj);
      } else {
        this.modularInfoList(
          this.table.pagination.pageSize,
          this.table.pagination.current - 1,
          this.searchForm.modularName
        );
      }
    },

    // 获取列表
    async modularInfoList(size, page, data) {
      const res = await modularInfoList(size, page, data);
      if (res.code === "200") {
        this.table.dataInfo = res.data.items;
        this.table.pagination.total = res.data.total;
        this.table.pagination.current = res.data.page + 1;
        this.table.pagination.pageSize = res.data.size;
      }
    },

    // 新建模块
    addModuleManage() {
      this.title = "添加";
      this.params.moduleIcon = "";
      this.params.moduleTitle = "";
      this.params.moduleType = "";
      this.visibleFloor = true;
    },

    //添加
    submitModule: debounce(function () {
      let obj = {
        ...this.params,
        id: this.id,
      };

      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.title == "添加") {
            const res = await addModule(this.params);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.visibleFloor = false;
              this.searchFormList();
            } else {
              this.$message.error(res.msg);
            }
          } else if (this.title == "编辑") {
            const res = await editModule(obj);
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.visibleFloor = false;
              this.searchFormList();
            } else {
              this.$message.error(res.msg);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 500),

    // 删除
    async deleteById(record) {
      const confirmData = {
        title: "提示",
        content: "是否确认删除？确认后关联数据将被删除",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.delet(record.id);
    },

    // 删除
    async delet(id) {
      let data = {};
      data.id = id;
      const res = await deleteModule(data);
      if (res.code === "200") {
        this.$message.success("删除成功");
        this.table.pagination.current = 1;
        this.searchFormList();
      } else {
        this.$message.error(res.msg);
      }
    },

    //查询
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm.modularName = "";
      this.searchFormList();
    },

    //编辑
    async edit(record) {
      this.title = "编辑";
      this.visibleFloor = true;
      this.id = record.id;
      this.modelarInfoById(this.id);
    },

    // 关闭蒙层的操作(取消校验)
    async cancel() {
      if (this.params.moduleIcon !== "" || this.params.moduleTitle !== "" || this.params.moduleType !== "") {
        const confirmData = {
          title: "提示",
          content: "是否确认返回？返回后，编辑数据将不会被保存。",
          type: ConfirmConfig.CONFIRM_TYPE_WARNING,
        };
        await this.$refs.confirmBoxModule.show(confirmData);
        this.visibleFloor = false;
        this.$refs.ruleForm.resetFields();
      } else {
        this.$refs.ruleForm.resetFields();
        this.visibleFloor = false;
      }
    },

    // 图片上传
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        this.params.moduleIcon = info.file.response.redirect_uri;
      }
    },

    // 图片限制
    beforeUpload(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 2;
      if (!isLt2M) {
        this.$message.error("图片最大2M");
        return Promise.reject();
      }
      // 校验比例
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === img.height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt2M && isSize;
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}

.moduleNo {
  color: red;
  margin-top: -5px;
  margin-bottom: -5px;
}

.imgText {
  margin-left: 17%;
  margin-top: -20px;
}

.imgUpload {
  width: 102px;
  height: 102px;
}

.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
