import {
  fetchApi
} from "@/utils/axios";
import URL from "@/api/urlConfig"

// 首页模块管理
export const modularInfoList = (size, page, data) => {
  const submitData = {
    '$paging.size': size,
    '$filter.name': 'null',
    '$paging.page': page,
    '$orderby': 'null',
    '$filter.params': data,
  };
  return fetchApi(URL.MODULAR_INFO_LIST, submitData)
}

// 新增
export const addModule = (data) => {
  return fetchApi(URL.ADD_MODULE, data, 'post', 'data')
}

// 删除
export const deleteModule = (data) => {
  return fetchApi(URL.DELETE_MODULE, data, 'post', 'params')
}

// 查看详情
export const modelarInfoById = (data) => {
  return fetchApi(URL.MODULAR_INFO_BYID, data, 'get')
}

// 编辑
export const editModule = (data) => {
  return fetchApi(URL.EDIT_MODULE, data, 'post', 'data')
}
